import { ChangeEvent } from 'react'
import { Box, Card, CardContent as MUICardContent, Chip, Grid, Typography, styled, IconButton, Button } from '@mui/material'
import { Check, DeleteOutlined } from '@mui/icons-material'
import { CustomerDetails } from '../get-customer-details-slice'
import { formatDate } from '../../../../utils/functions'
import { SimpleUploadButton } from '../../../../components/simple-upload-button'
import { DownloadLink } from '../../../../components/download-link'
import { Colors } from '../../../../theme/anility-dark-theme'

interface CustomerDetailsHeaderProps
{
  customerDetails?: CustomerDetails
  writeCustomer: boolean
  onUploadSignedOutreachLetter: (e: ChangeEvent) => void
  onDeleteSignedOutreachLetter: () => void
  onSendSSOInvites: () => void
  onSpecialRequirementsButtonClick?: () => void
}

const CardContent = styled(MUICardContent)({
  padding: '1rem',
  '&:last-child': {
    paddingBottom: '0.75rem'
  }
})

export const CustomerDetailsHeader = ({
  customerDetails,
  writeCustomer,
  onUploadSignedOutreachLetter,
  onDeleteSignedOutreachLetter,
  onSendSSOInvites,
  onSpecialRequirementsButtonClick
}: CustomerDetailsHeaderProps) => {
  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container rowGap={1}>
            <Grid item xs={12} display="inline-flex">
              <Typography variant="h3">{customerDetails?.name}</Typography>
              {customerDetails?.status && (
                <Chip variant="filled" label={customerDetails.status} size="small" color={customerDetails.status === 'Active' ? 'success' : 'error'} sx={{ mx: 1 }} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>Joined On: {formatDate(customerDetails?.joinedDate, 'MMMM, YYYY')}</Typography>
            </Grid>
            <Grid container alignItems={'center'} columnGap={2}>
              <Grid item alignItems="center">
              {customerDetails?.signedOutreachLetterPath
                ? <Box display="flex" alignItems="center" sx={{ mt: 0.25, mb: 0.25 }}>
                    <Check fontSize='small' style={{ color: '#00A664' }} />
                    <DownloadLink url={`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${customerDetails.id}/download-signed-outreach-letter`}>Signed Engagement Letter Provided</DownloadLink>
                    {writeCustomer && <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ color: Colors.primaryText, ml: 2 }}
                      onClick={onDeleteSignedOutreachLetter}
                      title='Delete Signed Engagement Letter'
                  >
                    <DeleteOutlined />
                  </IconButton>}
                  </Box>
                : writeCustomer && (<SimpleUploadButton
                id="upload-button-file"
                acceptedFiles="application/pdf"
                uploadText='Upload Signed Engagement Letter'
                onUploadFile={onUploadSignedOutreachLetter}
                />)
              }
              </Grid>
              <Grid item>
                <Button variant='contained' onClick={onSpecialRequirementsButtonClick}>Special Requirements</Button>
              </Grid>
            </Grid>
            {writeCustomer && customerDetails?.canSendSSOInvites && <Grid item xs={12}>
              <Button variant='contained' color='primary' onClick={onSendSSOInvites}>Send SSO Invites</Button>
            </Grid>}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { AnalystUsersItem } from '../get-analyst-users-slice'

interface GetReportWorkspaceDetailsState {
  reportWorkspaceDetails: ReportWorkspaceDetails | undefined
  loading: LoadingType
  error: string
}

export interface ReportWorkspaceDetails {
  id: string
  requestLink: string
  customerName: string
  customerFormSubmissionId: string
  responseLink: string
  targetCompanyName: string
  targetCompanyFormSubmissionId: string
  baseModelLink: string
  inProgressModelLink: string
  jsonDataPath?: string
  previewHtmlLink?: string
  reportUrl?: string
  isDeleted: boolean
  warnings: string[]
  status: 'Initializing' | 'In Progress' | 'Done' | 'Error'
  analystComments: string
  seqPermanentLinkLogs: ISeqPermanentLinkLogs[]
  assignedAnalyst?: AnalystUsersItem
  ppsrReportDataPath: string
  ppsrReportOriginalFileName: string
  ppsrReportFileSize: BigInt
  customerSpecialRequirements?: string
  entityAssessedSpecialRequirements?: string
  specialRequirementsConfirmed: boolean
  baseUrl: string
  reportConfigurationId: string
  reportType: string
  assessmentSubject: string
  htmlEndpoint: string
  validationChecks?: ValidationChecks
}

export interface ValidationChecks {
  hasValidationChecks: boolean
  validationChecksPassed: boolean
}

export interface ISeqPermanentLinkLogs
{
  logDate: string
  seqLogPermanentLinks: string
}

const initialState: GetReportWorkspaceDetailsState = {
  reportWorkspaceDetails: undefined,
  loading: 'idle',
  error: ''
}
interface ReportWorkspaceDetailsPayload {
  reportWorkspaceId: string
}
export const getReportWorkspacesDetails = createAsyncThunk<
  ReportWorkspaceDetails,
  ReportWorkspaceDetailsPayload,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('report/getReportWorkspaceDetails', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<ReportWorkspaceDetails>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getReportWorkspaceDetailsSlice = createSlice({
  name: 'get-report-workspace-details',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getReportWorkspacesDetails.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(
      getReportWorkspacesDetails.fulfilled,
      (state, { payload }) => {
        state.reportWorkspaceDetails = payload
        state.reportWorkspaceDetails.previewHtmlLink = `${process.env.REACT_APP_API_BASE}${state.reportWorkspaceDetails.htmlEndpoint}${state.reportWorkspaceDetails.id}`
        state.loading = 'loaded'
        state.error = ''
      }
    )
    builder.addCase(getReportWorkspacesDetails.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

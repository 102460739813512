import { Box } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { getCustomerDetails } from './get-customer-details-slice'
import { CustomerDetailsHeader } from './components/customer-details-header'
import { CustomerDetailsSummary } from './components/customer-details-summary'
import CustomerDetailsAssessment from './customer-assessments/customer-details-assessment'
import { setPageNumber } from './get-customer-users-slice'
import { setPageNumber as setCustomerSubsidiariesPageNumber } from './get-customer-subsidiaries-slice'
import { uploadSignedOutreachLetter } from './upload-signed-outreach-letter-slice'
import { deleteSignedOutreachLetter } from './delete-signed-outreach-letter-slice'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import SpecialRequirementsDialog from '../../../components/special-requirements-dialog'
import { updateCustomerSpecialRequirements } from './update-customer-special-requirements-slice'

const CustomerDetails = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [writeCustomer, readReports] = usePermission([AnilityScopes.Write.Customers, AnilityScopes.Read.Reports])

  const {
    getCustomerDetailsState,
    getOrderedAssessmentRequestsState,
    getUnderReviewAssessmentRequestsState,
    getReportWorkspacesState,
    getCompletedAssessmentRequestsState,
    getOnHoldAssessmentState,
    getExpiredAssessmentRequestsState,
    getAnalystUsersState,
    assignAnalystUserToAssessmentRequestsState,
    updateExpiredAssessmentState,
    uploadSignedOutreachLetterState,
    deleteSignedOutreachLetterState,
    reactivateDeclinedAssessmentRequestState,
    updateCustomerSpecialRequirementsState
  } = useAppSelector((state) => state)

  const loading = getCustomerDetailsState.loading === 'loading' ||
    getOrderedAssessmentRequestsState.loading === 'loading' ||
    getUnderReviewAssessmentRequestsState.loading === 'loading' ||
    getReportWorkspacesState.loading === 'loading' ||
    getCompletedAssessmentRequestsState.loading === 'loading' ||
    getOnHoldAssessmentState.loading === 'loading' ||
    getExpiredAssessmentRequestsState.loading === 'loading' ||
    getAnalystUsersState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' ||
    updateExpiredAssessmentState.loading === 'loading' ||
    reactivateDeclinedAssessmentRequestState.loading === 'loading' ||
    uploadSignedOutreachLetterState.loading === 'loading' ||
    deleteSignedOutreachLetterState.loading === 'loading'
  const customerDetails = getCustomerDetailsState.customerDetails
  const srLastModifiedByAnalystUser = `${customerDetails?.srLastModifiedByAnalystUserDto?.firstName ?? ''} ${customerDetails?.srLastModifiedByAnalystUserDto?.lastName ?? ''}`.trim()

  const [showSpecialRequirementsDialog, setShowSpecialRequirementsDialog] = useState(false)

  const refreshCustomerDetails = () => {
    if (!id || isNaN(+id)) return

    dispatch(
      getCustomerDetails({
        customerId: +id
      })
    )
    dispatch(setPageNumber(1))
    dispatch(setCustomerSubsidiariesPageNumber(1))
  }

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      refreshCustomerDetails()
    }
  }, [id])

  const handleUploadOutreachLetter = (event: ChangeEvent<any>) => {
    const customerId = getCustomerDetailsState?.customerDetails?.id
    if (!customerId || event.target.files.length < 1) { return }

    dispatch(uploadSignedOutreachLetter({
      customerId,
      file: event.target.files[0],
      onFinal: () => {
        dispatch(
          getCustomerDetails({
            customerId
          })
        )
      }
    }))
  }

  const handleDeleteOutreachLetter = () => {
    const customerId = getCustomerDetailsState?.customerDetails?.id
    if (!customerId || !getCustomerDetailsState?.customerDetails?.signedOutreachLetterPath) { return }

    dispatch(deleteSignedOutreachLetter({
      customerId,
      onFinal: () => {
        dispatch(
          getCustomerDetails({
            customerId
          })
        )
      }
    }))
  }

  const handleSendSSOInvites = () => {
    if (!getCustomerDetailsState?.customerDetails?.canSendSSOInvites) { return }
    alert('Send SSO Invites!')
  }

  const handleSpecialRequirementsButtonClick = () => {
    setShowSpecialRequirementsDialog(true)
  }

  const handleSpecialRequirementsClose = () => {
    setShowSpecialRequirementsDialog(false)
  }

  const handleSpecialRequirementsActionButtonClick = async (value: string) => {
    const customerId = getCustomerDetailsState?.customerDetails?.id
    if (customerId) {
      dispatch(updateCustomerSpecialRequirements({
        customerId,
        specialRequirements: value,
        onSuccess: () => {
          dispatch(
            getCustomerDetails({
              customerId
            })
          )
          setShowSpecialRequirementsDialog(false)
        }
      }))
    }
  }

  return (
    <>
      <Box p={4} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        rowGap: 2
      }}>
        <CustomerDetailsHeader
          writeCustomer={writeCustomer}
          customerDetails={getCustomerDetailsState.customerDetails}
          onUploadSignedOutreachLetter={handleUploadOutreachLetter}
          onDeleteSignedOutreachLetter={handleDeleteOutreachLetter}
          onSendSSOInvites={handleSendSSOInvites}
          onSpecialRequirementsButtonClick={handleSpecialRequirementsButtonClick}
        />
        <CustomerDetailsSummary customerDetails={getCustomerDetailsState.customerDetails} refreshCustomerDetails={refreshCustomerDetails} />
        {readReports && id && (<CustomerDetailsAssessment customerId={id} />)}
      </Box>
      <SpecialRequirementsDialog
        lastUpdatedOn={getCustomerDetailsState.customerDetails?.srLastModifiedDate}
        lastUpdatedBy={srLastModifiedByAnalystUser}
        value={getCustomerDetailsState.customerDetails?.specialRequirements}
        isLoading={updateCustomerSpecialRequirementsState.loading === 'loading'}
        open={showSpecialRequirementsDialog}
        onClose={handleSpecialRequirementsClose}
        onActionButtonClick={handleSpecialRequirementsActionButtonClick}
        isEditable={writeCustomer} />
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default CustomerDetails
